import React, { useState, useEffect } from "react";
import Axios from "axios";
import { isEmpty, find } from "lodash";
import { withDatabase } from "@nozbe/watermelondb/DatabaseProvider";
import Loader from "./Loader";
import { createApi, api } from "../utils";
import { startup } from "../db/queries/startup";
import { MAINTENANCE_WINDOW, NETWORK_ERROR_MESSAGE } from "../constants";

export const ConfigContext = React.createContext(null);

/**
 * Method to invoke the cloud function to get the widget config by ID
 * if id is not passed or if the widget is not found Error is thrown
 */
const getConfig = async (id) => {
  if (isEmpty(id)) {
    throw new Error("Widget is not configured");
  }

  let { data: config } = await Axios(
    `${process.env.REACT_APP_CLOUD_FUNCTION_BASE_URL}/getWidgetConfig?id=${id}`
  );

  if (isEmpty(config) || !config.siteName) {
    throw new Error("Widget not found");
  }

  return config || {};
};

/** Widget Config Context Provider
 * The widget config is fetched along with the widget theme based on the selected site id
 * If widget is embedded in a website then the parent URL is set and checked
 * Window size is also calculated to decide which UI to display
 * i.e. if window width is less that 768 then it is a mobile screen else it is desktop view
 */

const SiteConfigWrapper = ({
  database,
  setSelectedSiteId,
  setSitesInfo,
  ...props
}) => {
  const [loading, setLoading] = useState(true);
  const [value, setValue] = useState({});
  const [error, setError] = useState({ showError: false, message: "" });
  const [parentUrl, setParentUrl] = useState(null);
  const [listingId, setListingId] = useState(null);
  const [screenSize, setScreenSize] = useState(null);
  const [widgetTheme, setWidgetTheme] = useState(null);
  const [programId, setProgramId] = useState(null);
  const [teamId, setTeamId] = useState(null);
  const [widgetType, setWidgetType] = useState("registration");
  const [widgetSiteId, setWidgetSiteId] = useState(null);

  const [hasCustomFilters, setHasCustomFilters] = useState("false");

  useEffect(() => {
    const loadData = async () => {
      try {
        const url_string = window.location.href;
        const url = new URL(url_string);
        const id = url.searchParams.get("id");
        const ga = url.searchParams.get("ga");
        const hostnameParts = url.hostname.split('.');
        const domain = hostnameParts.length > 1 ? hostnameParts[1] : null;
        const screenSize = url.searchParams.get("windowWidth") || null;
        let parentUrlString = url.searchParams.get("url") || null;
        let widgetType = url.searchParams.get("la_widget_type") || "registration";
        let programId = url.searchParams.get("la_program_id") || null;
        let teamId = url.searchParams.get("la_team_id") || null;
        let widgetsiteId = url.searchParams.get("la_site_id") || null;
        let hasCustomFilters = "false";

        if (parentUrlString) {
          const parentUrl = new URL(parentUrlString);
          hasCustomFilters = parentUrl.searchParams.get("la-hasCustomFilters");
        }
        
        if (ga) {
          document.cookie = `_ga=${ga}; domain=.${domain}.com; path=/; Secure; SameSite=None`;
        }

        setListingId(id);
        setParentUrl(parentUrlString);
        setHasCustomFilters(hasCustomFilters);
        setScreenSize(parseInt(screenSize));
        // for schedule & team widget
        setProgramId(programId);
        setTeamId(teamId);
        setWidgetType(widgetType);
        setWidgetSiteId(widgetsiteId);
        const config = await getConfig(id);

        await setValue(config);
        if (config && !isEmpty(config)) {
          await createApi();
          if (config.sitesInfo) {
            // Multi site Case
            // Theme will be applied based on Selected Site, managed from settings tab on Admin
            let selectedSiteInfo =
              config.selectedSiteId &&
              config.selectedSiteId.length > 0 &&
              find(config.sitesInfo, { siteId: config.selectedSiteId });
            if (!selectedSiteInfo) {
              selectedSiteInfo = config.sitesInfo[0];
            }

            // league apps api to get theme settings based on site id
            const {
              data: {
                timezone,
                theme: { colors: theme },
              },
            } = await api.getSiteTheme(
              selectedSiteInfo.apiKey,
              selectedSiteInfo.siteId
            );
            config.timezone = timezone;
            config.theme = theme;
            await startup(database, config.sitesInfo);
            config.siteId = selectedSiteInfo.siteId;
            config.selectedSiteId = selectedSiteInfo.siteId;
          }
          setLoading(false);
          setSitesInfo(config.sitesInfo);
          setWidgetTheme(config.theme);
          setSelectedSiteId(config.selectedSiteId);
        }
      } catch (err) {
        setError({ showError: true, message: err.message });
      }
    };
    loadData();
  }, [database, setSelectedSiteId, setSitesInfo]);

  if (error.showError) {
    let errorContent;
    if (error.message === "Network Error") {
      // This is a short term solution for showing a helpful message during a planned downtime.
      const now = new Date();
      const maintenanceStart = new Date(MAINTENANCE_WINDOW.start);
      const maintenanceEnd = new Date(MAINTENANCE_WINDOW.end);
      if (now > maintenanceStart && now < maintenanceEnd) {
        errorContent = (
          <>
            <p className="error-title">{MAINTENANCE_WINDOW.title}</p>
            <p>{MAINTENANCE_WINDOW.message}</p>
          </>
        );
      } else {
        // Default network error message
        errorContent = (
          <p>
            {NETWORK_ERROR_MESSAGE.message1}&nbsp;
            {NETWORK_ERROR_MESSAGE.link}&nbsp;
            {NETWORK_ERROR_MESSAGE.message2}
          </p>
        );
      }
    } else {
      errorContent = <p>{error.message}</p>;
    }

    return <div className="error-content">{errorContent}</div>;
  }
  return loading ? (
    <Loader showLoading={loading} />
  ) : (
    <ConfigContext.Provider
      value={{
        ...value,
        parentUrl,
        hasCustomFilters,
        screenSize,
        listingId,
        theme: widgetTheme,
        widgetType,
        programId,
        teamId,
        widgetSiteId,
      }}
    >
      {props.children}
    </ConfigContext.Provider>
  );
};

export default withDatabase(SiteConfigWrapper);
