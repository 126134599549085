const getContentHeight = function () {
    var body = document.body,
        html = document.documentElement;

    return body.scrollHeight || html.scrollHeight;
}
// Send a message to the parent
export const sendMessage = function (type, msg, hideScroll, scrollBacktoPosition) {
    if (type === 'iframeLoaded') {
        setTimeout(() => {
            postMessage(type);
        }, 100);
    } else if (type === 'contentUpdate') {
        setTimeout(() => {
            msg = getContentHeight();
            postMessage(type, msg, hideScroll, scrollBacktoPosition)
        }, 100);
    } else if (type === 'scrollIframe') {
        setTimeout(() => {
            msg = getContentHeight();
            postMessage(type, msg, hideScroll, scrollBacktoPosition)
        }, 100);
    }
    else if(type === "updateURL"){
         setTimeout(() => {
            postMessage(type, msg, hideScroll, scrollBacktoPosition)
        }, 100);
    } else {
        postMessage(type, msg)
    }
};

const postMessage = (type, msg, hideScroll, scrollBacktoPosition) => {
    try {
        // Make sure you are sending a string, and to stringify JSON
        window.parent.postMessage(JSON.stringify({
            type: type,
            data: msg,
            hideScroll: hideScroll,
            scrollBacktoPosition: scrollBacktoPosition,
        }), '*');
    } catch (err) {
        console.error(err);
    }
}
