import React, { useEffect } from "react";
import { useHistory } from "react-router";
import clsx from "clsx";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

import { ConfigContext } from "./containers/SiteConfig";
import ProgramsList from "./components/ProgramsListing";
import ProgramsDetails from "./components/ProgramDetails";
import { SchedulesWidget } from "./components/scheduleWidget/ScheduleWidget";
import TeamsList from "./components/scheduleWidget/TeamsList";
import TeamSchedule from "./components/scheduleWidget/TeamSchedule";

import { useStyles } from "./utils";
import { sendMessage } from "./iframeConnect";

const RoutesList = () => {
  const history = useHistory();
  const classes = useStyles();

  const { widgetType } = React.useContext(ConfigContext);

  const notifyParentIframeLoaded = () => {
    sendMessage("iframeLoaded");
  };

  useEffect(() => {
    if (document.readyState === "complete" || document.readyState === "interactive") {
      notifyParentIframeLoaded();
    } else {
      document.addEventListener("DOMContentLoaded", notifyParentIframeLoaded);
    }
  
    return () => {
      document.removeEventListener("DOMContentLoaded", notifyParentIframeLoaded);
    };
  }, []);

  useEffect(() => {
    window.addEventListener(
      "message",
      function (event) {
        if (typeof event.data == "object") {
          if (event.data.call === "goBack") {
            history.goBack();
          } else if (event.data.type === "ga") {
            const url = new URL(window.location.href);
            const hostnameParts = url.hostname.split('.');
            const domain = hostnameParts.length > 1 ? hostnameParts[1] : null;
            document.cookie = `_ga=${event.data.data}; domain=.${domain}.com; path=/; Secure; SameSite=None`;
          }
        }
      },
      false
    );
  }, [history]);

  const renderRoutes = () => {
    switch (widgetType) {
      case "schedule":
        return (
          <Switch>
            <Route exact path="/" component={SchedulesWidget} />,
          </Switch>
        );
      case "team":
        return (
          <Switch>
            <Route exact path="/" component={TeamsList} />,
            <Route exact path="/teams/:teamId" component={TeamSchedule} />
          </Switch>
        );
      default:
        return (
          <Switch>
            <Route exact path="/" component={ProgramsList} />,
            <Route
              exact
              path="/details/:programId/:siteId"
              component={ProgramsDetails}
            />
          </Switch>
        );
    }
  };

  return <div className={clsx("App", classes.mainApp)}>{renderRoutes()}</div>;
};

const Routes = () => {
  return (
    <Router>
      <RoutesList />
    </Router>
  );
};

export default Routes;